/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Episode from "../templates/episode"
import { EpisodeConsumer, EpisodeProvider } from "../components/context"

export default function Index({ stuff }) {
  const data = useStaticQuery(graphql`
    {
      allContentfulEpisode(filter: { node_locale: { eq: "en-US" } }) {
        totalCount
        nodes {
          id
          name
          showNotes {
            childContentfulShowNotesTextShowNotesRichTextNode {
              json
            }
          }
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          image {
            fluid(maxWidth: 720) {
              ...GatsbyContentfulFluid
            }
          }
          johnRating
          mattRating
          createdAt
          publicationDate
          shortDescription
          season
          episodeNumber
          fields {
            slug
          }
        }
      }
    }
  `)

  const sortedEpisodes = data.allContentfulEpisode.nodes.sort((a, b) =>
    a.createdAt > b.createdAt && a.episodeNumber > b.episodeNumber ? -1 : 1
  )

  return (
    <EpisodeConsumer>
      {(context) => {
        return (
          <Episode
            data={{
              contentfulEpisode: context.state || sortedEpisodes[0],
            }}
          />
        )
      }}
    </EpisodeConsumer>
  )
}
